import React from "react"
import SEO from "../components/seo"


import Layout from "../components/layout"
import logo from "../images/2021PCS_logo-sm.png"

import carshowMagImg from "../images/carshow-mag.png"
import sidebarimage from "../images/MIMG_4940-330.jpg"
import sidebarposterimage from "../images/2023PCS_full_poster.webp"

import magazine from "../files/2020-Car-Show-Magazine.pdf"


import general_logo from "../images/general-logo.png"
import ms_jack_sparrow from "../images/ms-jack-sparrow.jpg"
import IMG_9296 from "../images/IMG_9296.jpg"
import IMG_9271 from "../images/IMG_9271.jpg"
import IMG_9304 from "../images/IMG_9304.jpg"
import IMG_9216 from "../images/IMG_9216.jpg"
import IMG_9226 from "../images/IMG_9226.jpg"
import IMG_9245 from "../images/IMG_9245.jpg"
import IMG_9194 from "../images/IMG_9194.jpg"
import IMG_9205 from "../images/IMG_9205.jpg"
import pie_show from "../images/pieshow.jpg"


import flyer from "../files/2023-Car-Show-flyers.pdf"
import poster from "../files/2023-Car-Show-half-sheet-flyers.pdf"
import fullposter from "../files/2023-Full_poster.pdf"
// import { Container, Row, Col } from "react-bootstrap"

import "../fontawesome/css/all.min.css"

// //import bootstrap styles
// import "../bootstrap/css/bootstrap.min.css"

//import my custom styles
import "./index.css"

const IndexPage = () => (

  <div className="App">
    <Layout>
    <SEO></SEO>
      <div className="container  ">

        <div className="row">
          <div className="col col-md-8">
            <div className="text-center big-lr-padding">
              <img src={general_logo} className="responsive" />
              <h1>We are looking forward to seeing you this Saturday, September 2, 2023 in Pardeeville, Wisconsin.</h1>
              <h2>Pro Tip: Gates open at 6:00 AM; avoid the 9:00 AM to 11:00 AM traffic jam at the entry gate by showing up early.</h2>
              <p> Reminder: Payment is only taken at the gate. We have NO advanced registration. It is a scam if you are asked to pay an advanced registration fee online. </p>
              <p>Find more info:</p>
              <a className="mb-3" href={poster} className="btn btn-warning" target="_blank" rel="noopener noreferrer">Download Full Poster</a>&nbsp;&nbsp;
              <a className="mb-3" href={fullposter} className="btn btn-warning" target="_blank" rel="noopener noreferrer">Download 1/2 Poster</a>&nbsp;&nbsp;
              <a className="mb-3" href={flyer} className="btn btn-warning" target="_blank" rel="noopener noreferrer">Download Flyer</a>&nbsp;&nbsp;
              <p className="mt-3"><a href="https://www.facebook.com/PardeevilleCarShow">Follow us on facebook</a> for all of the details</p>
             
             <h4>Welcome Back Minnesota's Jack Sparrow</h4>
              <img src={ms_jack_sparrow} className="float-end ms-2" />
              <p className="mt-3 text-start"><b>Minnesota's Jack Sparrow will once again be searching for his pirate booty at the car show.</b></p>
               <p className="mt-3 text-start">As Minnesota's Jack Sparrow, Joshua Godfredson's impersonation of Captain Jack Sparrow thrills kids and adults alike.  Now celebrating 13 years as MN Jack Sparrow, he is one of the longest running and most experienced in the world. With a mastery of Jacks movements, his voice, and a wardrobe worthy of Hollywood, MN Jack Sparrow keeps things very real and authentic to even the biggest fan.</p> 
  
   <p className="mt-3 text-start">FAIR WARNING: Don't be surprised if you "accidentally" learn some REAL pirate facts from this history buff, he spends a large amount of time teaching Pirate History to schools and historical events from coast to coast.  </p>
             
              <h2 className="mt-5">2022 - 18th Annual Pardeeville Community Car Show</h2>
            <h5>A BIG thank you to everyone!</h5>
            <p>
             It is with heartfelt gratitude in humbleness that we wish to thank everyone who attended and/or had a part in making the 18th Annual Pardeeville Community Car &amp; Truck Show a success this year. Each person from the prayers on our
              behalf, to
              baking of homemade pies, to working on the grounds at the various departments such as concessions, judging, parking, to donating items for the Silent Auction, to giving of your time and/or monetary donations is appreciated more than our
              words can
              express.
            </p>
              
            </div>
          </div>


          <div className="col col-md-4 text-center mt-4 side-bar">

            <h2 className="mt-2">Largest Car Show in the area</h2>

            <img  className="responsive" src={sidebarimage} alt="19th Annual Pardeeville Community Car &amp; Truck Show Largest Car Show in the area" />
            <h4>900+ cars, food &amp; fun</h4>           
            <hr className="m-3" />
              <h4>Get the Full poster</h4>
             <a className="mb-3" href={fullposter} className="btn btn-warning" target="_blank" rel="noopener noreferrer">
            <img  className="responsive" src={sidebarposterimage} alt="19th Annual Pardeeville Community Car &amp; Truck Show Largest Car Show in the area" />
          </a>

            <p className="text-center">
              <a href="https://www.facebook.com/PardeevilleCarShow/">Connect with us on Facebook
                <i className="fa fa-facebook-official fa-4x"></i>
              </a>
            </p>
            <hr className="m-3" />

            <h2>2021 Car Show Magazine</h2>
            <a href={magazine} className="" target="_blank" rel="noopener noreferrer">
              <img src={carshowMagImg} className="responsive" alt="17th Annual Pardeeville Community Car &amp; Truck Show Magazine" />
            </a>
            <p>Download our full color 2020/2021 Car Show Magazine, full of stories and great info about the Show.</p>


            <p> <a href={magazine} className="btn btn-warning" target="_blank" rel="noopener noreferrer">Download the Magazine (37MB)</a></p>

            <hr className="m-3" />
            <img src={pie_show} className="responsive" alt="Pie Show" />
            <p>Come and enjoy 100s of pies from local bakers, everything from standard apple or cherry to unique and yummy specialties.</p>
          </div>
        </div>

        <div className="row">
          <div className="col col-md-12">
            


            <hr className="m-5" />
            <h2>Flash Back to 2021 - 17th Car Show Awards</h2>
            <div className="row mb-5">
              <div className="col col-md-8">
                <h4>Overall Grand Champion</h4>
                <h5>2009 Nissan 370Z - Nick S.</h5>
                <img src={IMG_9296} className="responsive" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-md-8 offset-md-4">
                <h4>Best of Show Stock</h4>
                <h5>1960 Corvette - Bob C.</h5>
                <img src={IMG_9271} className="responsive" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-md-8">
                <h4>Best of Show Modified</h4>
                <h5>1939 Ford C.O.E - Earl P.</h5>
                <img src={IMG_9304} className="responsive" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-md-8 offset-md-4">
                <h4>Best Engine</h4>
                <h5>1930 Ford 5 Window - Brian Y.</h5>
                <img src={IMG_9216} className="responsive" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-md-8">
                <h4>Best Paint</h4>
                <h5>1957 Chevy Belair - Ted and Michelle M.</h5>
                <img src={IMG_9226} className="responsive" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-md-8 offset-md-4">
                <h4>Best Interior</h4>
                <h5>1955 Chevy Delray - Bob and Kaye K.</h5>
                <img src={IMG_9245} className="responsive" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-md-8">
                <h4>Participant's Choice</h4>
                <h5>2021 Ford GT - James S. (And Joe S.)</h5>
                <img src={IMG_9194} className="responsive" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col col-md-8  offset-md-4">
                <h4>Village President's Choice</h4>
                <h5>'69 GTO - Ken and Carie B.</h5>
                <img src={IMG_9205} className="responsive" />
              </div>
            </div>

          </div>
        </div> 
        
      </div>

 
    </Layout >
  </div >
)
export default IndexPage
